import React from 'react';

interface SpinnerProps {
    IsAbsolute?: boolean;
    IsWhite?: boolean;
};

const Spinner = ({
    IsAbsolute = false,
    IsWhite = false
}: SpinnerProps) => {
    return (
        <>
            <div className={"loader" + (IsAbsolute ? " loader-absolute" : " loader-static") + 
                (IsWhite ? " loader-white" : " loader-black")}>
                    <span style={{opacity:0}}>Loading...</span>
            </div>
        </>
    );
};

export default Spinner;