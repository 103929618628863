import React, { useEffect, useRef, useState } from 'react';
import axios, { Canceler } from 'axios';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { FlickrResponse, FlickrImage } from '../types/flickresponse';
import Nav from '../components/Nav';
import Spinner from '../components/Spinner';

//const ApiUrl1 = "https://api.flickr.com/services/feeds/photoset.gne?set=72157630065668978&nsid=79995756@N02&lang=en-us&format=json&nojsoncallback=1";
//const ApiUrl2 = "https://api.flickr.com/services/feeds/photoset.gne?set=72157630126265690&nsid=79995756@N02&lang=en-us&format=json&nojsoncallback=1";
//const ApiUrl3 = "https://api.flickr.com/services/feeds/photoset.gne?set=72157630063991387&nsid=79995756@N02&lang=en-us&format=json&nojsoncallback=1";

const PhotographyPage = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [lightboxOpen, setIsLightboxOpen] = useState<boolean>(false);
    const [lightboxImageUrl, setLightboxImageUrl] = useState<string>("");
    const [photos1, setPhotos1] = useState<FlickrResponse>(JSON.parse("{}"));
    const [photos2, setPhotos2] = useState<FlickrResponse>(JSON.parse("{}"));
    const [photos3, setPhotos3] = useState<FlickrResponse>(JSON.parse("{}"));
    const cancelRequestRef = useRef<(() => void) | null>(null);
    const isDev = process.env.NODE_ENV !== 'production';
    const [hasBeenSearched, setHasBeenSearched] = useState<boolean>(false);

    let ApiUrl1 = "/api/flickrapi.php?verify=true&albumid=72157630065668978";
    let ApiUrl2 = "/api/flickrapi.php?verify=true&albumid=72157630126265690";
    let ApiUrl3 = "/api/flickrapi.php?verify=true&albumid=72157630063991387";

    if (isDev) {
        ApiUrl1 = "https://riski23.tundramedia.net" + ApiUrl1 + "&dev=true";
        ApiUrl2 = "https://riski23.tundramedia.net" + ApiUrl2 + "&dev=true";
        ApiUrl3 = "https://riski23.tundramedia.net" + ApiUrl3 + "&dev=true";
    }

    const searchFlickr = async () => {
        setIsLoading(true);
        setHasBeenSearched(true);

        try {            
            const response1 = await axios(
                ApiUrl1,
                {
                    cancelToken: new axios.CancelToken((canceler: Canceler) => {
                        cancelRequestRef.current = canceler;
                    }),
                    timeout: 10000
                },
            );
            setPhotos1(response1.data);

            const response2 = await axios(
                ApiUrl2,
                {
                    cancelToken: new axios.CancelToken((canceler: Canceler) => {
                        cancelRequestRef.current = canceler;
                    }),
                    timeout: 10000
                },
            );
            setPhotos2(response2.data);

            const response3 = await axios(
                ApiUrl3,
                {
                    cancelToken: new axios.CancelToken((canceler: Canceler) => {
                        cancelRequestRef.current = canceler;
                    }),
                    timeout: 10000
                },
            );
            setPhotos3(response3.data);

            if (isDev) {
                console.log(ApiUrl1);
                console.log(response1.data);
                console.log(ApiUrl2);
                console.log(response2.data);
                console.log(ApiUrl3);
                console.log(response3.data);
            }

        } catch (error) {
            console.log("Axios error");
            if (!axios.isCancel(error)) {
                // TODO error handling & error message
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (!hasBeenSearched) {
            searchFlickr();             
        }    
    }, []);

    const handleImageClick = (Event: React.MouseEvent<HTMLAnchorElement>, ImageUrl: string) => {
        Event.preventDefault();
        const BigImageUrl = ImageUrl.replace("_m.jpg", "_b.jpg");
        setLightboxImageUrl(BigImageUrl);
        setIsLightboxOpen(true);
    };

    const closeImage = function () {
        setIsLightboxOpen(false);
    };

    return (
        <HelmetProvider>
            <Helmet>
                <title>Photography | Pete Riski</title>
            </Helmet>

            <div className="site">
                <div className="container">
                    <Nav ActivePage="about-photography" ActivePath="about" />
                    <div className="site-content">
                        {isLoading && (
                            <Spinner />
                        )}
                        {!isLoading && (photos1.items || photos2.items || photos3.items ) && (
                            <div className="gallery">
                                {photos1.items.map((item: FlickrImage, index: number) => (
                                    <a
                                        key={index}
                                        href={item.link}
                                        className="gallery-item"
                                        onClick={(e) => handleImageClick(e, item.media.m)}
                                    >
                                        <img className="gallery-image" src={item.media.m} alt="" />
                                    </a>
                                ))}
                                {photos2.items.map((item: FlickrImage, index: number) => (
                                    <a
                                        key={index}
                                        href={item.link}
                                        className="gallery-item"
                                        onClick={(e) => handleImageClick(e, item.media.m)}
                                    >
                                        <img className="gallery-image" src={item.media.m} alt="" />
                                    </a>
                                ))}
                                {photos3.items.map((item: FlickrImage, index: number) => (
                                    <a
                                        key={index}
                                        href={item.link}
                                        className="gallery-item"
                                        onClick={(e) => handleImageClick(e, item.media.m)}
                                    >
                                        <img className="gallery-image" src={item.media.m} alt="" />
                                    </a>
                                ))}
                            </div>
                        )} 
                    </div>   
                </div>            
            </div>

            {(lightboxOpen && lightboxImageUrl !== "") && (
                <div className="lightbox">
                    <div className="lightbox-outer">
                        <div className="lightbox-inner lightbox-inner-image">
                            <div className="lightbox-image-wrapper">
                                <img src={lightboxImageUrl} alt="" className="lightbox-image" />                                
                                <button
                                    className="lightbox-close lightbox-close-image"
                                    onClick={() => closeImage()}
                                >
                                    <span style={{display:"none"}}>Sulje</span>
                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.29289 2.70711C0.902369 2.31658 0.902369 1.68342 1.29289 1.29289C1.68342 0.902369 2.31658 0.902369 2.70711 1.29289L13 11.5858L23.2929 1.29289C23.6834 0.902369 24.3166 0.902369 24.7071 1.29289C25.0976 1.68342 25.0976 2.31658 24.7071 2.70711L14.4142 13L24.7071 23.2929C25.0976 23.6834 25.0976 24.3166 24.7071 24.7071C24.3166 25.0976 23.6834 25.0976 23.2929 24.7071L13 14.4142L2.70711 24.7071C2.31658 25.0976 1.68342 25.0976 1.29289 24.7071C0.90237 24.3166 0.90237 23.6834 1.29289 23.2929L11.5858 13L1.29289 2.70711Z" fill="white" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>                                  
                            </div>                       
                        </div>                       
                    </div>
                </div>
            )}
        </HelmetProvider>
    );
};

export default PhotographyPage;