import React from 'react';
import Nav from '../components/Nav';

const ErrorPage = () => {
    return (
        <div className="site">
            <div className="container">
                <Nav />
                <h1>Error</h1>    
            </div>            
        </div>
    );
};

export default ErrorPage;