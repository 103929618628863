import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Logo from './Logo';

interface NavProps {
    ActivePage?: string;
    ActivePath?: string;
};

const Nav = ({ ActivePage, ActivePath }: NavProps) => {
    const location = useLocation();
    const [isSubmenuVisible, setIsSubmenuVisible] = useState<boolean>(false);

    useEffect(() => {
        if (ActivePath && (ActivePath === "reel" || ActivePath === "archive" || ActivePath === "about")) {
            setIsSubmenuVisible(true);
        }
        else {
            setIsSubmenuVisible(false);
        }
    }, [ActivePath]);

    return (
        <>
            <div className="header">
                <Logo />
                <nav className="menu">
                    <ul className={isSubmenuVisible ? "menu-ul menu-ul-submenu" : "menu-ul"}>
                        <li className={ActivePath === "latest" ? "menu-li menu-li-active" : "menu-li"}>
                            <Link className={ActivePath === "latest" ? "menu-a menu-a-active" : "menu-a"} to="/">Latest</Link>
                        </li>
                        <li className={ActivePath === "reel" ? "menu-li menu-li-active" : "menu-li"}>
                            <Link className={ActivePath === "reel" ? "menu-a menu-a-active" : "menu-a"} to="/reel/reel-commercials">Reel</Link>
                            <ul className={ActivePath === "reel" ? "menu-ul-ul menu-ul-ul-active menu-ul-ul-reel" : "menu-ul-ul menu-ul-ul-reel"}>
                                <li className="menu-ul-li">
                                    <Link className={ActivePage === "reel-commercials" ? "menu-ul-a menu-ul-a-active" : "menu-ul-a"} to="/reel/reel-commercials">Commercials</Link>
                                </li>
                                <li className="menu-ul-li">
                                    <Link className={ActivePage === "reel-music-videos" ? "menu-ul-a menu-ul-a-active" : "menu-ul-a"} to="/reel/reel-music-videos">Music videos</Link>
                                </li>
                                <li className="menu-ul-li">
                                    <Link className={ActivePage === "reel-film" ? "menu-ul-a menu-ul-a-active" : "menu-ul-a"} to="/reel/reel-film">Film/TV</Link>
                                </li>
                            </ul>
                        </li>
                        <li className={ActivePath === "archive" ? "menu-li menu-li-active" : "menu-li"}>
                            <Link className={ActivePath === "archive" ? "menu-a menu-a-active" : "menu-a"} to="/archive/archive-commercials">Archive</Link>
                            <ul className={ActivePath === "archive" ? "menu-ul-ul menu-ul-ul-active menu-ul-ul-archive" : "menu-ul-ul menu-ul-ul-archive"}>
                                <li className="menu-ul-li">
                                    <Link className={ActivePage === "archive-commercials" ? "menu-ul-a menu-ul-a-active" : "menu-ul-a"} to="/archive/archive-commercials">Commercials</Link>
                                </li>
                                <li className="menu-ul-li">
                                    <Link className={ActivePage === "reel-music-videos" ? "menu-ul-a menu-ul-a-active" : "menu-ul-a"} to="/archive/archive-music-videos">Music videos</Link>
                                </li>
                            </ul>
                        </li>
                        <li className={ActivePath === "login" ? "menu-li menu-li-active" : "menu-li"}>
                            <Link className={ActivePath === "login" ? "menu-a menu-a-active" : "menu-a"} to="/login">Login</Link>
                        </li>
                        <li className={ActivePath === "about" ? "menu-li menu-li-active" : "menu-li"}>
                            <Link className={ActivePath === "about" ? "menu-a menu-a-active" : "menu-a"} to="/about/biography">About</Link>
                            <ul className={ActivePath === "about" ? "menu-ul-ul menu-ul-ul-active menu-ul-ul-about" : "menu-ul-ul menu-ul-ul-about"}>
                                <li className="menu-ul-li">
                                    <Link className={ActivePage === "about-biography" ? "menu-ul-a menu-ul-a-active" : "menu-ul-a"} to="/about/biography">Biography</Link>
                                </li>
                                <li className="menu-ul-li">
                                    <Link className={ActivePage === "about-awards" ? "menu-ul-a menu-ul-a-active" : "menu-ul-a"} to="/about/awards">Awards</Link>
                                </li>
                                <li className="menu-ul-li">
                                    <Link className={ActivePage === "about-photography" ? "menu-ul-a menu-ul-a-active" : "menu-ul-a"} to="/about/photography">Photography</Link>
                                </li>
                                <li className="menu-ul-li">
                                    <Link className={ActivePage === "about-books" ? "menu-ul-a menu-ul-a-active" : "menu-ul-a"} to="/about/books">Books</Link>
                                </li>
                            </ul>
                        </li>
                        <li className={ActivePath === "contact" ? "menu-li menu-li-active" : "menu-li"}>
                            <Link className={ActivePath === "contact" ? "menu-a menu-a-active" : "menu-a"} to="/contact">Contact</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </>        
    );
};

export default Nav;