import React from 'react';

interface VimeoIframeProps {
    VideoId: string;
};

const VimeoIframe = ({ VideoId }: VimeoIframeProps) => {
    return (
        <>
            <iframe
                src={`https://player.vimeo.com/video/${VideoId}?h=69a45790da&autoplay=1&color=ffffff&title=0&byline=0&portrait=0`}
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                }}
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
            />
            <script src="https://player.vimeo.com/api/player.js"></script>
        </>
    );
};

export default VimeoIframe;