import React, { useEffect, useRef, useState } from 'react';
import axios, { Canceler } from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'querystring';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Video from '../types/video';
import VimeoResponse from '../types/vimeoresponse';
import Nav from '../components/Nav';
import Spinner from '../components/Spinner';
import VideoTile from '../components/VideoTile';
import VimeoIframe from '../components/VimeoIframe';

interface CustomReelPageProps {
    ApiUrl: string;
    PagePath: string;
    PageTitle: string;
};

const CustomReelPage = ({
    ApiUrl,
    PagePath,
    PageTitle
}: CustomReelPageProps) => {
    const defaultMetaTitle = PageTitle;
    const location = useLocation();
    const navigate = useNavigate();
    const parsedQuery = queryString.parse(location.search.replace("?", ""));
    const [lightboxOpen, setIsLightboxOpen] = useState<boolean>(false);
    const [lightboxVideo, setLightboxVideo] = useState<string>("");
    const cancelRequestRef = useRef<(() => void) | null>(null);
    const [reelVideos, setReelVideos] = useState<VimeoResponse>(JSON.parse("{}"));
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [metaTitle, setMetaTitle] = useState<string>(defaultMetaTitle);
    const allLoaded = reelVideos.data && reelVideos.data.length > 0;
    const isDev = process.env.NODE_ENV !== 'production';

    const searchReel = async () => {
        setIsLoading(true);

        try {            
            const response = await axios(
                ApiUrl,
                {
                    cancelToken: new axios.CancelToken((canceler: Canceler) => {
                        cancelRequestRef.current = canceler;
                    }),
                    timeout: 20000
                },
            );
            setReelVideos(response.data);

            if (isDev) {
                console.log(ApiUrl);
                console.log(response);
            }

        } catch (error) {
            console.log("Axios error");
            if (!axios.isCancel(error)) {
                // TODO error handling & error message
            }
        }
        setIsLoading(false);
    };

    const closeVideo = function () {
        navigate(PagePath, { state: "login" });
    };

    const setVideoMetadata = function (videoId: string) {
        const videoUriString = `/videos/${videoId}`;
        let videoMetaTitle = "";

        if (videoMetaTitle == "") {
            const videoObj = reelVideos.data.find(obj => obj.uri == videoUriString);
            if (videoObj != null) {
                videoMetaTitle = `${videoObj.name} | ${defaultMetaTitle}`;
            };
        };

        if (videoMetaTitle != "") {
            setMetaTitle(videoMetaTitle);
        }
    };

    useEffect(() => {
        setReelVideos(JSON.parse("{}"));
        searchReel();           
    }, [ApiUrl]);

    useEffect(() => {
        if (parsedQuery.video && parsedQuery.video != "") {
            const videoId = parsedQuery.video.toString();
            setLightboxVideo(videoId);
            setIsLightboxOpen(true);
            document.body.classList.add('body-noscroll');
        }
        else {
            setIsLightboxOpen(false);
            setLightboxVideo("");
            setMetaTitle(defaultMetaTitle);
            document.body.classList.remove('body-noscroll');
        }
    }, [location.search]);

    useEffect(() => {
        if (parsedQuery.video &&
            parsedQuery.video != "" &&
            lightboxOpen &&
            allLoaded
        ) {
            setVideoMetadata(parsedQuery.video.toString());
        }
    }, [parsedQuery.video, lightboxOpen, reelVideos]);

    if (location.state && (location.state === "login" || location.state.lightbox)) {
        return (
            <HelmetProvider>
                <Helmet>
                    <title>{metaTitle} | Pete Riski</title>
                </Helmet>

                <div className="site">
                    <div className="container">
                        <Nav ActivePath="login" />
                        <div className="site-content">
                            {isLoading && (
                                <Spinner />
                            )}
                            {!isLoading && reelVideos.data && reelVideos.data.length > 0 && (
                                <div className="videolist videolist-4">
                                    {reelVideos.data.map((item: Video, index: number) => (
                                        <VideoTile
                                            key={index}
                                            Index={index}
                                            IsLatest={false}
                                            PagePath={PagePath}
                                            Video={item}
                                            Width={4}
                                        />
                                    ))}
                                </div>
                            )}                        
                        </div>                 
                    </div>
                    {isDev && (
                        <p>{reelVideos.developer_comment} // Timestamp: {reelVideos.developer_timestamp} // Expires : {reelVideos.developer_expires}</p>
                    )}             
                </div>

                {(lightboxOpen && lightboxVideo !== "") && (
                        <div className="lightbox">
                            <div className="lightbox-outer lightbox-outer-video">
                                <div className="lightbox-inner lightbox-inner-video">                  
                                    {allLoaded &&
                                        <VimeoIframe VideoId={lightboxVideo} />
                                    }
                                    <button
                                        className="lightbox-close lightbox-close-video"
                                        onClick={() => closeVideo()}
                                    >
                                        <span style={{display:"none"}}>Sulje</span>
                                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.29289 2.70711C0.902369 2.31658 0.902369 1.68342 1.29289 1.29289C1.68342 0.902369 2.31658 0.902369 2.70711 1.29289L13 11.5858L23.2929 1.29289C23.6834 0.902369 24.3166 0.902369 24.7071 1.29289C25.0976 1.68342 25.0976 2.31658 24.7071 2.70711L14.4142 13L24.7071 23.2929C25.0976 23.6834 25.0976 24.3166 24.7071 24.7071C24.3166 25.0976 23.6834 25.0976 23.2929 24.7071L13 14.4142L2.70711 24.7071C2.31658 25.0976 1.68342 25.0976 1.29289 24.7071C0.90237 24.3166 0.90237 23.6834 1.29289 23.2929L11.5858 13L1.29289 2.70711Z" fill="white" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </button>                                    
                                </div>
                            </div>
                        </div>
                    )}
            </HelmetProvider>              
        );         
    };

    return (
        <HelmetProvider>
            <Helmet>
                <title>Not found | Pete Riski</title>
            </Helmet>

            <div className="site">
                <div className="container">
                    <Nav ActivePath="login" />

                    <p>Access denied.</p>
                </div>
            </div>

        </HelmetProvider>
    );    
};

export default CustomReelPage;