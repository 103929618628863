import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Nav from '../components/Nav';
import Spinner from '../components/Spinner';

const ContactPage = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasBeenFetched, setHasBeenFetched] = useState<boolean>(false);
    const [content, setContent] = useState<string>("");

    const getContent = async () => {
        setIsLoading(true);
        setHasBeenFetched(true);
        try {
            await axios.get('/content/contact.html')
                .then(res => setContent(res.data))
                .catch(err => console.log(err));
        } catch (error) {
            console.log("Axios error");
            if (!axios.isCancel(error)) {
                // TODO error handling & error message
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (!hasBeenFetched) {
            getContent();
        }
    }, []);

    return (
        <HelmetProvider>
            <Helmet>
                <title>Contact | Pete Riski</title>
            </Helmet>

            <div className="site">
                <div className="container">
                    <Nav ActivePath="contact" />
                    <div className="site-content">
                        {isLoading && (
                            <Spinner />
                        )}
                        {!isLoading && content !== "" &&
                            <div dangerouslySetInnerHTML={{ __html: content }} />
                        }
                    </div>
                </div>
            </div>
        </HelmetProvider>
    );
};

export default ContactPage;