import React from 'react';
import { Link } from 'react-router-dom';
import VideoItem from '../types/video';

interface VideoTileProps {
    Index: number;
    IsLatest: boolean;
    PagePath: string;
    Video: VideoItem;
    Width: number;
};

const VideoTile = ({
    Index,
    IsLatest,
    PagePath,
    Video,
    Width
}: VideoTileProps) => {
    const videoId = Video.uri.split("/videos/");
    const sUsrAg = navigator.userAgent;
    console.log(sUsrAg);
    const isSafari = sUsrAg.indexOf("Safari") > -1 && !(sUsrAg.indexOf("Chrome") > -1);

    return (
        <Link
            className={"videolist-item videolist-item-" + Width.toString() + (IsLatest && Index === 0 ? " videolist-item-latest" : "")}
            to={`${PagePath}?video=${videoId[1]}`}
            state={{ lightbox: "lightbox" }}
        >
            <img className={"videolist-item-image" + (IsLatest && Index === 0 ? " videolist-item-image-latest" : "")} src={Video.pictures[0].link} alt={Video.name} loading="lazy" />
            <p className={"videolist-item-title"}>
                <span className={isSafari ? " safari500" : ""}>
                    {Video.name}
                </span>
            </p>
        </Link>
    );
};

export default VideoTile;