import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Nav from '../components/Nav';

const LoginPage = () => {
    const navigate = useNavigate();
    const [inputText, setInputText] = useState<string>("");
    const [errorState, setErrorState] = useState<boolean>(false);

    const handleTextChange = (Event: React.ChangeEvent<HTMLInputElement>) => {
        const password = Event.target.value;
        setInputText(password);
    };

    const handleSubmit = (Event: React.FormEvent) => {
        setErrorState(false);
        Event.preventDefault();
        if (inputText === "customreel01") {
            navigate("/login/custom-reel-01", { state: "login" });
        }
        else if (inputText === "customreel02") {
            navigate("/login/custom-reel-02", { state: "login" });
        }
        else if (inputText === "customreel03") {
            navigate("/login/custom-reel-03", { state: "login" });
        }
        else if (inputText === "customreel04") {
            navigate("/login/custom-reel-04", { state: "login" });
        }
        else if (inputText === "customreel05") {
            navigate("/login/custom-reel-05", { state: "login" });
        }
        else if (inputText === "customreel06") {
            navigate("/login/custom-reel-06", { state: "login" });
        }
        else if (inputText === "customreel07") {
            navigate("/login/custom-reel-07", { state: "login" });
        }
        else if (inputText === "customreel08") {
            navigate("/login/custom-reel-08", { state: "login" });
        }
        else if (inputText === "customreel09") {
            navigate("/login/custom-reel-09", { state: "login" });
        }
        else if (inputText === "customreel10") {
            navigate("/login/custom-reel-10", { state: "login" });
        }
        else if (inputText === "customreel11") {
            navigate("/login/custom-reel-11", { state: "login" });
        }
        else if (inputText === "customreel12") {
            navigate("/login/custom-reel-12", { state: "login" });
        }
        else if (inputText === "customreel13") {
            navigate("/login/custom-reel-13", { state: "login" });
        }
        else if (inputText === "customreel14") {
            navigate("/login/custom-reel-14", { state: "login" });
        }
        else if (inputText === "customreel15") {
            navigate("/login/custom-reel-15", { state: "login" });
        }
        else {
            setInputText("");
            setErrorState(true);
        }
    };

    return (
        <HelmetProvider>
            <Helmet>
                <title>Login | Pete Riski</title>
            </Helmet>

            <div className="site">
                <div className="container">
                    <Nav ActivePath="login" />

                    <form className="login-form" onSubmit={(e => handleSubmit(e))}>
                        <label className="login-label">
                            Password
                            <input
                                id="login-password"
                                name="login-password"
                                className="login-input"
                                type="password"
                                onChange={(e) => handleTextChange(e)}
                                value={inputText}
                            />
                        </label>
                        <button type="submit" className="login-submit">
                            Log in
                        </button>
                        {errorState &&(
                            <p className="login-error">Invalid password.</p>
                        )}
                    </form>
                </div>
            </div>
        </HelmetProvider>
    );
};

export default LoginPage;