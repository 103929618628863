import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import AwardsPage from './pages/AwardsPage';
import BiographyPage from './pages/BiographyPage';
import BooksPage from './pages/BooksPage';
import ContactPage from './pages/ContactPage';
import CustomReelPage from './pages/CustomReelPage';
import ErrorPage from './pages/ErrorPage';
import LoginPage from './pages/LoginPage';
import PhotographyPage from './pages/PhotographyPage';
import VideoListPage from './pages/VideoListPage';

import './App.css';

let apiBaseUrl = '/api/api3.php?albumid=';
const isDev = process.env.NODE_ENV !== 'production';

if (isDev) {
  apiBaseUrl = 'https://riski23.tundramedia.net/api/api3.php?dev=true&albumid=';
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  // LATEST / HOME
  {
    path: '/',
    element:
      <VideoListPage
        ActivePath='latest'
        ApiUrl={`${apiBaseUrl}1944854&verify=true"`}
        Columns={4}
        IsLatest
        PagePath="/"
        PageTitle={isDev ? "[DEV] Latest" : "Latest"}
      />,
    errorElement: <ErrorPage />
  },

  // REEL
  {
    path: '/reel/reel-commercials',
    element:
      <VideoListPage
        ActivePage='reel-commercials'
        ActivePath='reel'
        ApiUrl={`${apiBaseUrl}1944861&verify=true"`}
        Columns={4}
        PagePath="/reel/reel-commercials"
        PageTitle="Commercials"
      />
  },
  {
    path: '/reel/reel-music-videos',
    element:
      <VideoListPage
        ActivePage='reel-music-videos'
        ActivePath='reel'
        ApiUrl={`${apiBaseUrl}1953409&verify=true"`}
        Columns={3}
        PagePath="/reel/reel-music-videos"
        PageTitle="Music videos"
      />
  },
  {
    path: '/reel/reel-film',
    element:
      <VideoListPage
        ActivePage='reel-film'
        ActivePath='reel'
        ApiUrl={`${apiBaseUrl}1953414&verify=true"`}
        Columns={3}
        PagePath="/reel/reel-film"
        PageTitle="Film / TV"
      />
  },

  // ARCHIVE
  {
    path: '/archive/archive-commercials',
    element:
      <VideoListPage
        ActivePage='archive-commercials'
        ActivePath='archive'
        ApiUrl={`${apiBaseUrl}1953417&verify=true&per_page=10"`}
        Columns={5}
        PagePath="/archive/archive-commercials"
        PageTitle="Commercials"
      />
  },
  {
    path: '/archive/archive-music-videos',
    element:
      <VideoListPage
        ActivePage='archive-music-videos'
        ActivePath='archive'
        ApiUrl={`${apiBaseUrl}1953446&verify=true"`}
        Columns={5}
        PagePath="/archive/archive-music-videos"
        PageTitle="Music videos"
      />
  },

  // ABOUT
  {
    path: '/about/biography',
    element: <BiographyPage />
  },
  {
    path: '/about/awards',
    element: <AwardsPage />
  },
  {
    path: '/about/photography',
    element: <PhotographyPage />
  },
  {
    path: '/about/books',
    element: <BooksPage />
  },

  // LOGIN & CUSTOM REEL
  {
    path: '/login',
    element: <LoginPage />
  },
  {
    path: '/login/custom-reel-01',
    element:
      <CustomReelPage
        ApiUrl={`${apiBaseUrl}1953425&verify=true"`}
        PagePath={"/login/custom-reel-01"}
        PageTitle={"Custom reel 01"}
      />
  },
  {
    path: '/login/custom-reel-02',
    element:
      <CustomReelPage
        ApiUrl={`${apiBaseUrl}1963405&verify=true"`}
        PagePath={"/login/custom-reel-02"}
        PageTitle={"Custom reel 02"}
      />
  },
  {
    path: '/login/custom-reel-03',
    element:
      <CustomReelPage
        ApiUrl={`${apiBaseUrl}1963406&verify=true"`}
        PagePath={"/login/custom-reel-03"}
        PageTitle={"Custom reel 03"}
      />
  },
  {
    path: '/login/custom-reel-04',
    element:
      <CustomReelPage
        ApiUrl={`${apiBaseUrl}1963409&verify=true"`}
        PagePath={"/login/custom-reel-04"}
        PageTitle={"Custom reel 04"}
      />
  },
  {
    path: '/login/custom-reel-05',
    element:
      <CustomReelPage
        ApiUrl={`${apiBaseUrl}1963412&verify=true"`}
        PagePath={"/login/custom-reel-05"}
        PageTitle={"Custom reel 05"}
      />
  },
  {
    path: '/login/custom-reel-06',
    element:
      <CustomReelPage
        ApiUrl={`${apiBaseUrl}1963414&verify=true"`}
        PagePath={"/login/custom-reel-06"}
        PageTitle={"Custom reel 06"}
      />
  },
  {
    path: '/login/custom-reel-07',
    element:
      <CustomReelPage
        ApiUrl={`${apiBaseUrl}1963416&verify=true"`}
        PagePath={"/login/custom-reel-07"}
        PageTitle={"Custom reel 07"}
      />
  },
  {
    path: '/login/custom-reel-08',
    element:
      <CustomReelPage
        ApiUrl={`${apiBaseUrl}1963419&verify=true"`}
        PagePath={"/login/custom-reel-08"}
        PageTitle={"Custom reel 08"}
      />
  },
  {
    path: '/login/custom-reel-09',
    element:
      <CustomReelPage
        ApiUrl={`${apiBaseUrl}1963420&verify=true"`}
        PagePath={"/login/custom-reel-09"}
        PageTitle={"Custom reel 09"}
      />
  },
  {
    path: '/login/custom-reel-10',
    element:
      <CustomReelPage
        ApiUrl={`${apiBaseUrl}1963424&verify=true"`}
        PagePath={"/login/custom-reel-10"}
        PageTitle={"Custom reel 10"}
      />
  },
  {
    path: '/login/custom-reel-11',
    element:
      <CustomReelPage
        ApiUrl={`${apiBaseUrl}1963425&verify=true"`}
        PagePath={"/login/custom-reel-11"}
        PageTitle={"Custom reel 11"}
      />
  },
  {
    path: '/login/custom-reel-12',
    element:
      <CustomReelPage
        ApiUrl={`${apiBaseUrl}1963427&verify=true"`}
        PagePath={"/login/custom-reel-12"}
        PageTitle={"Custom reel 12"}
      />
  },
  {
    path: '/login/custom-reel-13',
    element:
      <CustomReelPage
        ApiUrl={`${apiBaseUrl}1963430&verify=true"`}
        PagePath={"/login/custom-reel-13"}
        PageTitle={"Custom reel 13"}
      />
  },
  {
    path: '/login/custom-reel-14',
    element:
      <CustomReelPage
        ApiUrl={`${apiBaseUrl}1963432&verify=true"`}
        PagePath={"/login/custom-reel-14"}
        PageTitle={"Custom reel 14"}
      />
  },
  {
    path: '/login/custom-reel-15',
    element:
      <CustomReelPage
        ApiUrl={`${apiBaseUrl}1963433&verify=true"`}
        PagePath={"/login/custom-reel-15"}
        PageTitle={"Custom reel 15"}
      />
  },

  // CONTACT
  {
    path: '/contact',
    element: <ContactPage />
  }
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);